import React, { useState } from "react"
import { graphql } from "gatsby"

import * as api from "../services/api"

import Layout from "../components/_shared/Layout"
import SEO from "../components/_shared/SEO"

import SearchSection from "../components/Search/SearchSection"

// ----------------------------------------------------------------------------
// GraphQL query - export and gatsby takes in automatic way
// ----------------------------------------------------------------------------

export const pageQuery = graphql`
	query {
		allPrismicFaq(sort: { fields: first_publication_date, order: ASC }) {
			edges {
				node {
					data {
						answer {
							text
						}
						question {
							text
						}
					}
				}
			}
		}
	}
`

// ----------------------------------------------------------------------------

const SearchPage = props => {
	const [filteredFAQList, setFilteredFAQList] = useState([])

	// -------------------------------------

	function mapPrismicFAQ() {
		const {
			data: {
				allPrismicFaq: { edges },
			},
		} = props

		return (
			edges &&
			edges.map(edge => {
				return {
					question: edge.node.data.question.text,
					answer: edge.node.data.answer.text,
				}
			})
		)
	}

	function handleFAQsSearch(text) {
		api.getFilteredFAQs(text).then(list => setFilteredFAQList(list))
	}

	// -------------------------------------

	const faqList =
		(filteredFAQList && filteredFAQList.length > 0 && filteredFAQList) ||
		mapPrismicFAQ()

	return (
		<Layout>
			<SEO title="Ricerca" />

			<SearchSection
				faqList={faqList}
				location={props.location}
				onSearch={handleFAQsSearch}
			/>
		</Layout>
	)
}

export default SearchPage
