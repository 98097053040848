import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"
import Masonry from "react-masonry-component"

import RevealOnScroll from "../../_shared/RevealOnScroll"
import Divider from "../../_shared/Divider"
import MasonryCard from "../../_shared/MasonryCard"
import SearchBar from "../../_shared/SearchBar"

// ----------------------------------------------------------------------------

function SearchSection(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { faqList, location, onSearch } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [queryString, setQueryString] = useState("")

  useEffect(() => {
    if (location && location.search) {
      const params = new URLSearchParams(location.search)
      const searchString = params.get("query") || ""

      setQueryString(searchString)
      onSearch(searchString)
    } else {
      setQueryString()
      onSearch("")
    }
  }, [location.search])

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function isMobile() {
    const { theme } = props

    if (typeof window !== "undefined") {
      const mediaQuery = theme.mediaQueries && theme.mediaQueries.mobile

      const matchMediaString = `(max-width: ${mediaQuery})`

      const matchMedia = window.matchMedia(matchMediaString)

      if (matchMedia.matches) {
        return true
      }
    }

    return false
  }

  // -------------------------------------

  function renderFAQCards() {
    return (
      faqList &&
      faqList.map(faq => {
        return (
          <RevealOnScroll key={faq.question}>
            <MasonryCard
              className="search-card"
              question={faq.question}
              answer={faq.answer}
            />
          </RevealOnScroll>
        )
      })
    )
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const masonryOptions = {
    transitionDuration: "0",
    columnWidth: 324,
    resize: true,
    gutter: isMobile() ? 0 : 14,
    horizontalOrder: true,
    fitWidth: true,
  }

  return (
    <section className={props.className}>
      <SearchBar className="mobile-search-bar" placeholder="Qualche dubbio?" />
      <div className="search-query-container">
        <span>
          <span>Risultati di ricerca per:</span>&nbsp;
          <span className="query">{queryString}</span>
        </span>
      </div>

      <Divider className="divider" />
      <Masonry
        className={"search-grid"} // default ''
        options={masonryOptions} // default {}
        disableImagesLoaded={false} // default false
        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
        imagesLoadedOptions={{}} // default {}
        enableResizableChildren
      >
        {renderFAQCards()}
      </Masonry>
    </section>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

SearchSection.propTypes = {
  className: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  faqList: PropTypes.array,
  location: PropTypes.object,
  onSearch: PropTypes.func,
}

SearchSection.defaultProps = {
  faqList: [],
  onSearch: () => {},
}

// ----------------------------------------------------------------------------

const SearchSectionWithTheme = withTheme(SearchSection)

// ----------------------------------------------------------------------------

const StyledSearchSection = styled(SearchSectionWithTheme)`
  & {
    padding-top: 100px;
    margin: auto;
    margin-bottom: 78.5px;

    width: 70vw;
    max-width: 980px;

    .mobile-search-bar {
      display: none;
    }

    .search-query-container {
      display: flex;
      flex-direction: row;

      margin-bottom: 25.7px;

      span {
        font-size: 18px;
        line-height: 24px;
        color: ${props => props.theme.primaryTextColor};
      }

      .query {
        font-weight: bold;
      }
    }

    .search-grid {
      margin: auto;
      margin-top: 44px;
      width: 70vw;
    }

    .search-bar {
      width: 100vw;
    }

    .search-card {
      margin-bottom: 14px;
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 0 0;
      width: 100vw;
      max-width: 100vw;

      .mobile-search-bar {
        display: flex;
      }

      .search-query-container {
        display: none;
      }

      .divider {
        display: none;
      }

      .search-grid {
        width: 100vw !important;
        max-width: 100vw;
        height: auto !important;
        overflow: hidden;

        > div {
          position: relative !important;
          top: unset !important;
          left: unset !important;

          display: flex;
          flex-direction: row;
          justify-content: center;
        }
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledSearchSection
